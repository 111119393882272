
import { computed, defineComponent, reactive, } from "vue";
import { translate } from "@/hooks/TranslateService";

export default defineComponent({
  name: "kt-account",
  components: {
  },
  setup(){
    const selectedUser = reactive(
        computed(() => {
          return JSON.parse(
            localStorage.getItem("simulate_user") as string
          )
        })
    );

    return{
      translate,
      selectedUser
    }
  }
});
